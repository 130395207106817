import Header from 'components/Header'
import Thirdparty from 'components/service/Thirdparty'
import { publicConfig } from 'config'
import { getds } from 'ds'
import { useViewCounter } from 'hooks/use-view-counter'
import dynamic from 'next/dynamic'
import { useCallback, useEffect } from 'react'
import { CommonDataContext } from 'shared-components/contexts/CommonDataContext'
import { DealBarContext } from 'shared-components/contexts/DealBarContext'
import { NotificationsContext } from 'shared-components/contexts/NotificationsContext'
import { PlaywireContext } from 'shared-components/contexts/PlaywireContext'
import { PostContext } from 'shared-components/contexts/PostContext'
import { ThemeContext } from 'shared-components/contexts/ThemeContext'
import { UIVariantContext } from 'shared-components/contexts/UIVariantContext'
import { defaultAdInferePath } from 'shared-components/hooks/use-ad-path'
import { useIsPreview } from 'shared-components/hooks/use-is-preview'
import Footer from 'shared-components/layout/Footer'
import PageTransition from 'shared-components/layout/PageTransition'
import PopupWrapper from 'shared-components/layout/PopupWrapper'
import UnderNavBlock from 'shared-components/layout/UnderNavBlock'
import AdPath from 'shared-components/service/AdPath'
import styleUtils from 'shared-components/styles/util.module.css'
import Logo from 'shared-svg/AuthorityMedia.svg'
import { DSGetDealBar } from 'types.aa.ds'
import styles from './Layout.module.css'
import { AppEventsContext } from 'shared-components/contexts/AppEventsContext'
import { appEventListeners, mergeAppEventsListeners } from 'shared-code/app-events-listeners'
import Main from 'shared-components/layout/Main'
import { appEventsListenersAA } from '../app-events-listeners-aa'

const AdminPanel = dynamic(() => import('shared-components/layout/AdminPanel'), { ssr: false })
const NotificationsLayer = dynamic(() => import('shared-components/layout/NotificationsLayer'))

const eventListeners = {
  listeners: mergeAppEventsListeners(appEventListeners, appEventsListenersAA),
}
const Layout: React.FC<React.PropsWithChildren> = ({ children }) => {
  const isPreview = useIsPreview()
  const { queue } = NotificationsContext.useContainer()
  const { postId } = PostContext.useContainer()
  const { ready, theme } = ThemeContext.useContainer()
  const {
    showAdminPanel,
    // common: { dealBar },
  } = CommonDataContext.useContainer()
  useEffect(() => {
    if (!ready) {
      return
    }

    document.body.classList[theme === 'dark' ? 'add' : 'remove'](styleUtils.darkTheme)
  }, [ready, theme])

  useViewCounter(postId)

  const getDealbar = useCallback(async (...args: Parameters<DSGetDealBar>) => {
    const dataSource = await getds()
    return dataSource.getDealBar(...args)
  }, [])
  return (
    <PlaywireContext.Provider
      initialState={{
        inst: null,
        enabled: !publicConfig('adsDisabled'),
        adClassList: publicConfig('adClassList'),
      }}
    >
      <AppEventsContext.Provider initialState={eventListeners}>
        <UIVariantContext.Provider initialState={{ postPreviewScore: 'line', buttonStyle: 'fill' }}>
          <DealBarContext.Provider initialState={{ getDealbar, dealbar: null }}>
            <AdPath
              inferePath={(path, tags) => {
                if (path === '/') {
                  return '/'
                }
                return defaultAdInferePath(path, tags)
              }}
            />
            {showAdminPanel ? <AdminPanel editPostTpl={publicConfig('postEditLink')} /> : null}
            <Header />
            <UnderNavBlock />
            <Main className={styles.main}>{children}</Main>
            <Footer logo={Logo} logoLink={{ href: publicConfig('authorityMediaLink') }} />
            <PopupWrapper />
            <PageTransition />
            {queue.length ? <NotificationsLayer /> : null}
            {!isPreview ? <Thirdparty /> : null}
          </DealBarContext.Provider>
        </UIVariantContext.Provider>
      </AppEventsContext.Provider>
    </PlaywireContext.Provider>
  )
}

export default Layout
